import React from "react"

class Trio extends React.Component {
  render() {
    const { icon1, icon2, icon3 } = this.props
    const { title1, title2, title3 } = this.props
    const { text1, text2, text3 } = this.props
    return (
      <div className="my-10 md:my-25">
        <div className="mx-auto flex w-full max-w-twelve justify-center flex-col items-center lg:flex-row">
          <div className="p-12 flex justify-between flex-col w-5/6 md:w-4/6 lg:w-2/6 items-center text-center font-hairline">
            <div>
              <img src={icon1} alt={title1} />
            </div>
            <h3 className="text-xl mb-4 w-48">{title1}</h3>
            <p className="">{text1}</p>
          </div>
          <div className="p-12 flex justify-between flex-col w-5/6 md:w-4/6 lg:w-2/6 items-center text-center font-hairline">
            <div>
              <img src={icon2} alt={title2} />
            </div>
            <h3 className="text-xl mb-4 w-48">{title2}</h3>
            <p>{text2}</p>
          </div>
          <div className="p-12 flex justify-between flex-col w-5/6 md:w-4/6 lg:w-2/6 items-center text-center font-hairline">
            <div>
              <img src={icon3} alt={title3} />
            </div>
            <h3 className="text-xl mb-4 w-48">{title3}</h3>
            <p>{text3}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Trio
