import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Trio from "../components/Trio"
import Brands from "../components/Brands"
import Contact from "../components/Contact"
import AboutHero from "../components/AboutHero"
import MainImage from "../components/MainImage"
import OrangeCTA from "../components/OrangeCTA"
import { useAboutQuery } from "../hooks/useAboutQuery"
import YearsInBusiness from "../components/YearsInBusiness"

const AboutPage = () => {
  const data = useAboutQuery()
  const short = data.allSanityAbout.edges[0].node
  return (
    <Layout>
      <SEO
        title="About Us | "
      />
      <AboutHero
        heroImage={short.heroImage.asset.fluid}
        heroTitle={short.heroTitle}
        heroSubtitle={short.heroSubtitle}
        icon1={short.heroIcon1.asset.fluid.src}
      />
      <MainImage
        mainTitle={short.mainTitle}
        mainText={short._rawMainText}
        mainImg={short.mainImg.asset.fluid.src}
        mainBtn={short.mainBtn}
        slug="/about/#contact"
      />
      <Trio
        icon1={short.services1Icon.asset.fluid.src}
        icon2={short.services2Icon.asset.fluid.src}
        icon3={short.services3Icon.asset.fluid.src}
        title1={short.services1Title}
        title2={short.services2Title}
        title3={short.services3Title}
        text1={short.services1Text}
        text2={short.services2Text}
        text3={short.services3Text}
      />
      <OrangeCTA
        title={short.orangeCtaTitle}
        text={short._rawOrangeCtaText}
        btn={short.orangeCtaBtn}
        overlayImg={short.orangeCtaOverlayImg.asset.fluid}
        slug="/about/#contact"
      />
      <Brands />
      <YearsInBusiness />
      <Contact />
    </Layout>
  )
}

export default AboutPage
