import React from "react"
import Button from "./Button"
import PortableText from "@sanity/block-content-to-react"

class MainImage extends React.Component {
  render() {

    const { mainBtn, mainTitle, mainText, mainImg, slug, fontSize } = this.props

    return (
      <div className="my-20 lg:my-25 mx-auto font-hairline text-dark">
        <div className="max-w-twelve flex text-center lg:text-left px-10 flex-col mx-auto lg:flex-row">
          {/* LEFT CONTAINER */}
          <div
            className="flex-1 flex flex-col"
            style={{ padding: "10px", marginRight: "30px" }}
          >
            <h2 className="mb-5 text-2xl">{mainTitle}</h2>
            <div className="w-110px border border-2 mx-auto lg:mx-0 border-green mb-5"></div>
            <PortableText blocks={mainText} />
          </div>
          <div
            className="flex-1 flex flex-col items-center"
            style={{ padding: "10px", marginLeft: "30px" }}
          >
            <img 
            src={mainImg}
            className=""
            style={{ maxHeight: "270px" }}
            alt={mainTitle}
            />
          </div>
        </div>
        <div className="mx-auto md:p-0 px-5" style={{ marginTop: "45px", maxWidth: "410px", fontSize: `${fontSize}` }}>
          <Button btnText={mainBtn} bgColor="bg-green" slug={slug} />
        </div>
      </div>
    )
  }
}

export default MainImage